let timeoutId: ReturnType<typeof setTimeout> | undefined

export default function () {
  const { $yotpo } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()

  onMounted(() => {
    if (!isIntegrationEnabled('yotpo')) return

    /*  On first load, even after yotpo script is loaded, there is some magical time until their crap doesn't blow up
        TypeError: Cannot read property 'unbindEvents' of undefined
        at e.refreshWidgets (widget.js:1)
        at t.refreshWidgets (widget.js:4)
        at VueComponent.<anonymous> (app.js:9402)
        at Array.<anonymous> (commons.app.js:13579)
        at flushCallbacks (commons.app.js:13505)
    */
    $yotpo.addCallback(() => {
      try {
        if (timeoutId) clearTimeout(timeoutId)
        const api = $yotpo.getApi()
        timeoutId = setTimeout(function () {
          api.refreshWidgets()
        }, 2000)
      } catch (err) {
        // do nothing, we hate you yotpo..
      }
    })

    $yotpo.load()
  })
}
